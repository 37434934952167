import "./Healthy.css"
import forsmall from "./img/forsmall.png"
import line from "./img/line.png"
import forlarge from "./img/forlarge.png"
import { BsArrowRight } from 'react-icons/bs';

const Healthy=()=>{
return(
    <div className="Healthy"
    style={{backgroundImage: `url(${line})`,
    backgroundRepeat: 'no-repeat',
   
    }} >
        <div className="know" >
<a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE">
            <img src={forsmall} className="forsmall" alt=""height={300} />
</a>
    <img src={forlarge} alt="" height={500} />
       <div className="txt">
        {/* <span>WE HELP YOU STAY HEALTHY</span> */}
        <h1>Other Solutions</h1>
        <p>Our workshops and programs on human performance are designed to meet the unique needs of CXOs, athletes, and corporate teams.
</p>
<p>We specialize in crafting transformative experiences for elite performers across the spectrum. With our blend of experiential learning and evidence-based practices, we empower you to transcend your current capabilities and reach new heights of success.
</p>
<a href="https://forms.zohopublic.in/myfunc/form/ContactUs/formperma/O6s4XTeitrSyb2ZkBZEYKQxJgrHtuTJomMsAIzshnyE">
   <button>Know More <BsArrowRight/></button> 
</a>

</div>
        </div>
</div>
)
}
export default Healthy;