import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Link } from 'react-router-dom'
import "./ReportoOtp.css"
import { auth } from '../../../firebase'
import { RecaptchaVerifier, signInWithPhoneNumber } from '@firebase/auth'
import { useUserAuth } from '../../../State/UserAuthContext'

function ReportOtp() {
    const [number, setNumber]= useState("");
    const [error, setError] = useState("");
    const [otp, setOtp] = useState("");
    const [expandForm, setExpandForm] = useState(false)
    const {updateUser } = useUserAuth()
    const {user } = useUserAuth()
// useEffect(()=>{
//   console.log(user)
// },[])
    const generateRecaptcha = () => {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible'
      });
    }
   
  const getOtp=(e)=>{
    e.preventDefault()
    setError("")

  if(number.length >= 12){
  generateRecaptcha();


  let appVerifier = window.recaptchaVerifier;
  setExpandForm(true)
  
  signInWithPhoneNumber(auth, number, appVerifier)  
  .then((confirmationResult) =>{
    window.confirmationResult = confirmationResult;
  }).catch((error)=>{
    console.log(error);
  });
  }

}

const verifyOtp=(e)=>{
  if (otp.length === 6) {
    console.log("number is", number)
    const phoneNumber = number
    let confirmationResult = window.confirmationResult;

    confirmationResult.confirm(otp)
    .then((result) => {
      const user = result.user;
      console.log(user);
      updateUser(user);

      // Call the sendToWhatsApp function with the appropriate message and phone number
      const message = "Your report is generated. Welcome!";
      sendToWhatsApp(message, phoneNumber);

    })
    
    .catch((error)=>{
console.log(error)
    })
  }
}

const sendToWhatsApp = async (message, phoneNo) => {
    try {
      const response = await fetch('http://localhost:5000/api/send-whatsapp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: phoneNo,
          body: message,
        }),
      });

      if (response.ok) {
        console.log('Message sent successfully.');
      } else {
        console.error('Failed to send message.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };


  return (
    <>
      {/* <div className="box">
        <div className="shapes">
          <div className="ellipse" />
          <div className="div" />
          <div className="ellipse-2" />
          <div className="ellipse-3" />
        </div>
      </div> */}
      <div className='Login'>
        <div className="login-area">
          <div className="heading-area">
            <h2>{expandForm ? 'Enter your OTP' : 'WELCOME'}</h2>
          </div>
          <div className="inputs">
            <div className="input-error">
              {expandForm ? (
                <input type="text" onChange={(e) => setOtp(e.target.value)} placeholder='Enter your OTP' />
              ) : (
                <PhoneInput
                  defaultCountry='IN'
                  placeholder="Enter phone number"
                  value={number}
                  onChange={setNumber}
                />
              )}
              <span>{error}</span>
            </div>
          </div>
          <div className="button-area">
            {expandForm ? (
              <button onClick={verifyOtp}>Submit OTP</button>
            ) : (
              <button onClick={getOtp}>Send OTP</button>
            )}
          </div>
          <div className="recaptcha-container" id='recaptcha-container' />
        </div>
      </div>
    </>
  );
};

export default ReportOtp;
