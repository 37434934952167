import React from "react";
import "./Our.css"
import squat from "./squat.png"
import arrow from "./chevron-right.png";
import { Link } from "react-router-dom";
function Squat() {
  return (
    <div className='squat'>
      <h1>Squats</h1>
      <h3>
      Empower your body and mind with squats
      </h3>
      <p>A simple yet powerful exercise that builds strength, tones muscles, and enhances overall flexibility. Unleash the transformative benefits of this fundamental movement for a healthier, more resilient you.</p>
      <img className='image' src={squat} alt="" height={400}/>
      <Link to="/camera" ><button>Start Assessment <img src = { arrow } width={ 24 } height={ 24 } /></button></Link>      
    </div>
  )
}

export default Squat