import React from 'react'

import "../../Media/Pages/Page.css"
function Bseven() {
  return (
    <div className='page-body' style={{display:"flex", alignItems:"center"}}>
    <img style={{width:"70%"}} src="https://www.rishi.fit/static/media/nutritionForInjury.ffad4ab4a0e2d168e78a.png" alt="" />
   <div className="page-text-area">
    
     <div className="agenda-area">
        <h3>Nutrition for injury recovery</h3>
        <p>Injuries are an unpreventable part of practising and support in sport. The specific aftereffects of a workout incited injury may differ broadly relying upon the nature and seriousness of the injury. Injures regularly bring about discontinuance, or possibly a decrease, in cooperation in sport and diminished physical action. More extreme wounds may bring about the immobilization of a limb. Ongoing proof proposes that half of the total number of wounds can be viewed as extreme, prompting a typically a 3 weeks without training. Subsequently, medications that can build the pace of recuperating and lessening an opportunity to re-visitation of play are significant. Among different alternatives utilized via mentors, doctors and candidates, nourishing help may help improve recuperation. A lot of material has been composed on the subject of sustenance for work out instigated wounds. however, next to no stems from legitimately looking at these issues. The point of this analysis is to analyze and refresh the proof for healthful procedures to help the improvement of recuperation and re-visitation of preparation and rivalry.</p>
   <p>Nourishment is one technique to counter the negative effect of an activity that prompted the injury. Insufficiencies of energy, protein and different supplements ought to be evaded. Cases for the viability of numerous different supplements following wounds are wild, however, the proof is obscure. The aftereffects of training prompted injury may shift broadly relying upon the idea of the injury and seriousness. Wounds commonly bring about discontinuance, or if nothing else a decrease, in cooperation in sport and diminished physical movement. Appendage fixed status might be important with certain wounds, adding to diminished activity and training. Following a physical issue, a provocative reaction is started and keeping in mind that overabundance irritation might be hurtful, given the significance of the incendiary cycle for wound recuperating, endeavouring to decrease aggravation may not be ideal for ideal recuperation. Wounds extreme enough for immobilization of an appendage bring about loss of bulk and diminished muscle quality and capacity. Loss of muscle results from decreases in basal muscle protein combination and the obstruction of muscle to anabolic incitement. Energy balance is basic. Higher protein admissions appear to be justified during immobilization. In any event, care ought to be taken not to diminish the outright measure of protein consumption when energy admission is decreased. There is promising, yet primer, proof for the utilization of omega-3 unsaturated fats to counter muscle misfortune and improve hypertrophy, separately. The abrogating wholesome proposal for harmed exercisers ought to be to burn-through and even eating routine dependent on entirely, insignificantly handled nourishments or fixings produced using entire food sources. The eating regimen arrangement ought to be deliberately evaluated and changes considered as the injury mends and action designs change.</p>
 <p>Nourishments you ought to consider adding to your eating regimen to help recover from a physical issue all the more rapidly.</p>
   <p>1. Protein is a significant structure block for some tissues in your body, including muscle after a games injury, the harmed body part is regularly immobilized. This, by and large, prompts a decrease in quality and bulk. Nonetheless, getting enough protein can help limit this misfortune. Yet, a protein-rich eating routine may help keep the irritation from getting awful and hindering your recovery.</p>
   <p>2. Fiber-Rich Foods recuperation from injury frequently includes immobilization or restricted utilization of the harmed body part. To keep this from getting an undesirable muscle to fat ratio, it’s critical to repay by eating marginally less. One approach to diminish your calorie admission is to burn-through an eating regimen of healthy fibre. This, alongside devouring the protein-rich nourishments referenced above, will assist you with eating less without feeling hungry.</p>
<p>3. Vitamin C helps your body make collagen, which helps retain the quality of your bones, muscles, skin and tendons. Accordingly, getting enough vitamin C from your diet is a great way to help your body renovate tissue after an injury. Further, vitamin C has antioxidant and anti-inflammatory properties, which may help speed up your healing by staving off undue levels of inflammation.</p>
<p>4. Omega 3 these fats are found in foods such as fish, algae, walnuts, flaxseeds and chia seeds, which are known to have anti-inflammatory properties. You can also prevent excess or prolonged inflammation by limiting omega-6 fats, which are commonly found in corn, canola, cottonseed, soy and sunflower oils.</p>
<p>5. Zinc: Studies show that not getting enough zinc from your diet can delay wound healing. Eating meat, fish, shellfish, pulses, seeds, nuts and whole grains may help you recover more effectively from injury.</p>
<p>6. Creatine is a substance naturally found in meat, poultry and fish. It helps your body produce energy during heavy lifting or high-intensity exercise. The human body can also produce about 1 gram of it per day. Creatine has become a popular supplement commonly used to increase muscle mass and improve performance in various sports.</p>
<p>7. Glucosamine is a natural substance found in the fluid that surrounds your joints. It is involved in the creation of tendons, ligaments and cartilage. Your body naturally produces glucosamine, but you can also increase your levels through supplements. Supplements are generally made either from shellfish shells or fermented corn. Research in individuals with arthritis shows that glucosamine may be useful in decreasing joint pain.</p>
     </div>

  
   </div>
    </div>
  )
}

export default Bseven
