import React from 'react'
import "./Ourtwo.css"

import second from "./second.png"
function Ourtwo() {
  return (
    <div className='ourtwo'>
     
      <div className="second-img">
        <img src={second} alt="" height={400} />
      </div>
      <div className="second-text">
    <h1>X60 OUTDOOR</h1>
    {/* <h3>MEdX is a cutting-edge platform co-created by tech and medical elites, revolutionizing neuroscience and anatomy education for medical students.
</h3> */}
{/* <p>Tailored for premier medical institutes, MEdX aspires to transform conventional learning, offering students an immersive and comprehensive educational journey, priming them to become the next generation of healthcare visionaries.
</p> */}
<p>At X60 we believe in the power of nature and its profound impact on our health. Our fitness studio is nestled outdoors, encouraging synchronization with the environment as you train. We focus on functional movements that promote strength, flexibility, and balance, enhancing your health in the most natural setting. Alongside our fitness philosophy, we emphasize the importance of nutrition from real food, guiding you to nourish your body with the best that nature offers.</p>
<a target='_blank' href="https://www.x60outdoor.com/"><button>Know more</button>
</a>
      </div>
    </div>
  )
}

export default Ourtwo
