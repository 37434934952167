import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from '@firebase/auth';
import { useUserAuth } from '../../../State/UserAuthContext';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import './Report.css';
import './ReportoOtp.css';

const Report = () => {
    const [Name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [expandForm, setExpandForm] = useState(false);
    const [errors, setErrors] = useState({});
    const [verificationError, setVerificationError] = useState(null); // New state to track OTP verification error
    const navigate = useNavigate();
    const { updateUser } = useUserAuth();
  
    const generateRecaptcha = () => {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible'
      });
    };
  
    const getOtp = (e) => {
      e.preventDefault();
      const newErrors = {};
  
      if (phoneNo.length < 12) {
        newErrors.phoneNo = 'Please enter a valid phone number';
      } else {
        newErrors.phoneNo = '';
      }
  
      setErrors(newErrors);
  
      if (phoneNo.length >= 12) {
        // Move recaptchaVerifier creation and signInWithPhoneNumber here
        generateRecaptcha();
        let appVerifier = window.recaptchaVerifier;
  
        signInWithPhoneNumber(auth, phoneNo, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setExpandForm(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
  
    const verifyOtp = (e) => {
      e.preventDefault();
      const newErrors = {};
  
      if (otp.length !== 6) {
        newErrors.otp = 'Please enter a valid 6-digit OTP';
      }
  
      setErrors(newErrors);
  
      if (Object.keys(newErrors).length === 0) {
        const phoneNumber = phoneNo;
        let confirmationResult = window.confirmationResult;
  
        confirmationResult
          .confirm(otp)
          .then((result) => {
            const user = result.user;
            console.log(user);
            updateUser(user);
  
            // Call the sendToWhatsApp function with the appropriate message and phone number
            const message = 'Your report is generated. Welcome!';
            // sendToWhatsApp(message, phoneNumber);
            sendPdfToEmail(e);
            
  
            // Reset verificationError state if OTP verification is successful
            setVerificationError(null);
          })
          .catch((error) => {
            console.log(error);
  
            // Handle OTP verification error
            setVerificationError('Invalid OTP. Please try again.');
          });
      }
    };
  
    // const sendToWhatsApp = async (message, phoneNo) => {
    //   try {
    //     const response = await fetch('http://localhost:5000/api/send-whatsapp', {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify({
    //         to: phoneNo,
    //         body: message,
    //       }),
    //     });
  
    //     if (response.ok) {
    //       console.log('Message sent successfully.');
    //     } else {
    //       console.error('Failed to send message.');
    //     }
    //   } catch (error) {
    //     console.error('Error sending message:', error);
    //   }
    // };
    const sendPdfToEmail = async (event) => {
      console.log("email is", email)
      event.preventDefault(); // Prevent the default form submission behavior
  
      try {
        const response = await fetch('http://localhost:5000/send-pdf', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            to: email,
          }),
        });
  
        if (response.ok) {
          console.log('Message sent successfully.');
        } else {
          console.error('Failed to send message.');
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    };

   

    return (
        <div className="container">
          <h2>Enter Details</h2>
          {expandForm ? (
            <form onSubmit={verifyOtp}>
              <label>
                <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter your OTP" />
                {errors.otp && <span className="error">{errors.otp}</span>}
                {verificationError && <span className="error">{verificationError}</span>}
              </label>
              <div className="button-container">
                <button type="submit">Submit OTP</button>
              </div>
            </form>
          ) : (
            <form onSubmit={getOtp}>
              <label>
                Name:
                <input type="text" value={Name} onChange={(e) => setName(e.target.value)} required />
              </label>
              <label>
                Last Name:
                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
              </label>
              <label>
                Email:
                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </label>
              <label>
                Phone No.:
                <PhoneInput value={phoneNo} onChange={setPhoneNo} required />
                {errors.phoneNo && <span className="error">{errors.phoneNo}</span>}
              </label>
              <div className="button-container">
                <button type="submit">Send OTP</button>
              </div>
            </form>
          )}
          <div className="recaptcha-container" id="recaptcha-container" />
        </div>
      );
    };
    
    export default Report;


// import React from "react";
// // import { Component2 } from "./Component2";
// import "./Report.css";

// export const Report = () => {
//   return (
//     <div className="details">
//       <div className="overlap-wrapper">
//         <div className="overlap">
//           <div className="overlap-group">
//             {/* <Component2 className="component" /> */}
//             <img className="vector" alt="Vector" src="vector-12.svg" />
//             <div className="shapes">
//               <div className="ellipse" />
//               <div className="div" />
//               <div className="ellipse-2" />
//               <div className="ellipse-3" />
//             </div>
//             <div className="rectangle" />
//             <div className="text-wrapper">ENTER DETAILS</div>
//             <div className="group">
//               <div className="group-2">
//                 <div className="name">NAME</div>
//                 <div className="rectangle-2" />
//               </div>
//               <div className="group-3">
//                 <div className="LAST-name">LAST NAME</div>
//                 <div className="rectangle-2" />
//               </div>
//               <div className="group-4">
//                 <div className="text-wrapper-2">WORK EMAIL ADDRESS</div>
//                 <div className="rectangle-2" />
//               </div>
//               <div className="group-5">
//                 <div className="text-wrapper-2">PHONE NUMBER</div>
//                 <div className="rectangle-2" />
//               </div>
//             </div>
//             <div className="knowmore">
//               <div className="group-wrapper">
//                 <div className="overlap-group-wrapper">
//                   <div className="overlap-group-2">
//                     <div className="rectangle-3" />
//                     <div className="rectangle-4" />
//                     <div className="group-6">
//                       <div className="text-wrapper-3">SUBMIT</div>
//                       <img className="chevron-right" alt="Chevron right" src="chevron-right.svg" />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="shapes-2">
//             <div className="ellipse" />
//             <div className="div" />
//             <div className="ellipse-2" />
//             <div className="ellipse-3" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Report;


// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { auth } from '../../../firebase';
// import { RecaptchaVerifier, signInWithPhoneNumber } from '@firebase/auth';
// import { useUserAuth } from '../../../State/UserAuthContext';
// import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
// import './Report.css';
// import './ReportoOtp.css';
// import arrow from "./chevron-right.png";
// import line from "./line.png";

// const Report = () => {
//   const [Name, setName] = useState('');
//   const [lastName, setLastName] = useState('');
//   const [phoneNo, setPhoneNo] = useState('');
//   const [email, setEmail] = useState('');
//   const [otp, setOtp] = useState('');
//   const [expandForm, setExpandForm] = useState(false);
//   const [error, setError] = useState('');
//   const navigate = useNavigate();
//   const { updateUser } = useUserAuth();

//   const generateRecaptcha = () => {
//     window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});
//   };

//   const getOtp = (e) => {
//     e.preventDefault();
//     // setError('');

//     if (phoneNo.length < 12) {
//         setError('Please enter a valid phone number');
//     } 
//     else{
//         setError('');
//     }

//     if (phoneNo.length >= 12) {
//       generateRecaptcha();

//       let appVerifier = window.recaptchaVerifier;
//       setExpandForm(true);

//       signInWithPhoneNumber(auth, phoneNo, appVerifier)
//         .then((confirmationResult) => {
//           window.confirmationResult = confirmationResult;
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   };

//   const verifyOtp = (e) => {
//     e.preventDefault();
//     if (otp.length === 6) {
//       const phoneNumber = phoneNo;
//       let confirmationResult = window.confirmationResult;

//       confirmationResult
//         .confirm(otp)
//         .then((result) => {
//           const user = result.user;
//           console.log(user);
//           updateUser(user);

//           // Call the sendToWhatsApp function with the appropriate message and phone number
//           const message = 'Your report is generated. Welcome!';
//           sendToWhatsApp(message, phoneNumber);
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   };

//   const sendToWhatsApp = async (message, phoneNo) => {
//     try {
//       const response = await fetch('http://localhost:5000/api/send-whatsapp', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           to: phoneNo,
//           body: message,
//         }),
//       });

//       if (response.ok) {
//         console.log('Message sent successfully.');
//       } else {
//         console.error('Failed to send message.');
//       }
//     } catch (error) {
//       console.error('Error sending message:', error);
//     }
//   };

//   return (
//     <div className="container"> 
//       <h2>ENTER DETAILS</h2>
//       {expandForm ? (
//         <form onSubmit={verifyOtp}>
//           <label>
//             <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter your OTP" />
//           </label>
//           <div className="button-container">
//             <button type="submit">Submit OTP</button>
//           </div>
//         </form>
//       ) : (
//         <form onSubmit={getOtp}>
//           <div className="label-container">
//             <div className="input-group">
//           <label>
//             NAME
//             <input type="text" value={Name} onChange={(e) => setName(e.target.value)} required />
//           </label>
//           <label>
//             LAST NAME
//             <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
//             </label>
//             </div>
//             <div className="input-group">
//           <label>
//             WORK EMAIL ADDRESS
//             <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
//           </label>

//           <label>
//             PHONE NUMBER
//             <input value={phoneNo} onChange={setPhoneNo} required/>
//           </label>
//           </div>
//           </div>

//           <div className="button-container">
//             <button type="submit">SUBMIT <img src = { arrow } width={ 24 } height={ 24 } /></button>
//           </div>
//         </form>
//       )}
//       <div className="recaptcha-container" id="recaptcha-container" />
//     </div>
//   );
// };

// export default Report;




// // import React from "react";
// // // import { Component2 } from "./Component2";
// // import "./Report.css";

// // export const Report = () => {
// //   return (
// //     <div className="details">
// //       <div className="overlap-wrapper">
// //         <div className="overlap">
// //           <div className="overlap-group">
// //             {/* <Component2 className="component" /> */}
// //             <img className="vector" alt="Vector" src="vector-12.svg" />
// //             <div className="shapes">
// //               <div className="ellipse" />
// //               <div className="div" />
// //               <div className="ellipse-2" />
// //               <div className="ellipse-3" />
// //             </div>
// //             <div className="rectangle" />
// //             <div className="text-wrapper">ENTER DETAILS</div>
// //             <div className="group">
// //               <div className="group-2">
// //                 <div className="name">NAME</div>
// //                 <div className="rectangle-2" />
// //               </div>
// //               <div className="group-3">
// //                 <div className="LAST-name">LAST NAME</div>
// //                 <div className="rectangle-2" />
// //               </div>
// //               <div className="group-4">
// //                 <div className="text-wrapper-2">WORK EMAIL ADDRESS</div>
// //                 <div className="rectangle-2" />
// //               </div>
// //               <div className="group-5">
// //                 <div className="text-wrapper-2">PHONE NUMBER</div>
// //                 <div className="rectangle-2" />
// //               </div>
// //             </div>
// //             <div className="knowmore">
// //               <div className="group-wrapper">
// //                 <div className="overlap-group-wrapper">
// //                   <div className="overlap-group-2">
// //                     <div className="rectangle-3" />
// //                     <div className="rectangle-4" />
// //                     <div className="group-6">
// //                       <div className="text-wrapper-3">SUBMIT</div>
// //                       <img className="chevron-right" alt="Chevron right" src="chevron-right.svg" />
// //                     </div>
// //                   </div>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //           <div className="shapes-2">
// //             <div className="ellipse" />
// //             <div className="div" />
// //             <div className="ellipse-2" />
// //             <div className="ellipse-3" />
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };
// // export default Report;
