import React from 'react'
import "./Our.css"
import ourthree from "./ourthree.png"


function OurThree() {
  return (
    <div className='our'>
    
      <div className="our-text">
        <h1>MEdX</h1>
        <h3>
        <strong className='func'>MEdX</strong> is a cutting-edge platform co-created by tech and medical elites, revolutionizing neuroscience and anatomy education for medical students.
        </h3>
        <p>Tailored for premier medical institutes, MEdX aspires to transform conventional learning, offering students an immersive and comprehensive educational journey, priming them to become the next generation of healthcare visionaries.</p>

       <a target='_blank' href='https://myfunc.in/'><button>Know more</button></a>
      </div>
      <div className="our-img">
        <img src={ourthree} alt="" height={400}/>
      </div>
    </div>
  )
}

export default OurThree;
