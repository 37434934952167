import React, { useRef, useState, useEffect } from "react";
import * as tf from "@tensorflow/tfjs";
import * as posenet from "@tensorflow-models/posenet";
import Webcam from "react-webcam";
import { drawKeypoints, drawSkeleton } from "./utilities";
import "./Camera.css"
import squatFrontView from "./side squat.mp4";
import playButtonImage from "./play.png";
import { useNavigate } from 'react-router-dom';
import arrow from "./chevron-right.png";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function Camera() {
  const navigate = useNavigate();
  const webcamRef = useRef(null)
  const canvasRef = useRef(null)
  const videoRef = useRef(null);

  const [kneeAngles, setKneeAngles] = useState([]);
  const [count, setCount] = useState(0);
  const [distance, setDistance] = useState('')
  const [flag, setFlag] = useState("")
  const [goMessage, setGoMessage] = useState("")
  const [elbowBend, setElbowBend] = useState("")
  const [keypointsData, setKeypointsData] = useState([]);
  const [playing, setPlaying] = useState(false);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [webcamPlaying, setWebcamPlaying] = useState(false);
  const [timer, setTimer] = useState(5);
  const [assessmentCompleted, setAssessmentCompleted] = useState(false);

  let frame_id = 0;
  let initial_dist = 0;
  let initial_gap = 0;

  useEffect(() => {
    // This useEffect runs when the component mounts
    runPosenet();
  }, []);

  const runPosenet = async () => {
    // console.log("runPosenet");
    const net = await posenet.load(
      {
        inputResolution: { width: 320, height: 240 },
        scale: 0.5,
      });

    setInterval(() => {
      detect(net);
      //debouncedDetectSquats(net);  
    }, 500);
  };

  const startCountdown = () => {
    const countdownInterval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(countdownInterval);
      setGoMessage("You can start now");
      setTimeout(() => {
        setGoMessage("");
      }, 2000);
    }, 5000);

    setTimeout(() => {
      setGoMessage("Assessment Completed");
    }, 10000);

    setTimeout(() => {
      setAssessmentCompleted(true);
      stopCam();
    }, 12000);
  };

  const detect = async (net) => {

    if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null && webcamRef.current.video.readyState === 4) {
      // Get Video Properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Set video width
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      // Make Detections
      const pose = await net.estimateSinglePose(video);
      frame_id = frame_id + 1;

      const leftHip = pose.keypoints.find((keypoint) => keypoint.part === "leftHip");
      const rightHip = pose.keypoints.find((keypoint) => keypoint.part === "rightHip");
      const leftKnee = pose.keypoints.find((keypoint) => keypoint.part === "leftKnee");
      const rightKnee = pose.keypoints.find((keypoint) => keypoint.part === "rightKnee");
      const leftAnkle = pose.keypoints.find((keypoint) => keypoint.part === "leftAnkle");
      const rightAnkle = pose.keypoints.find((keypoint) => keypoint.part === "rightAnkle");
      const leftShoulder = pose.keypoints.find((keypoint) => keypoint.part === "leftShoulder");
      const rightShoulder = pose.keypoints.find((keypoint) => keypoint.part === "rightShoulder");
      const leftElbow = pose.keypoints.find((keypoint) => keypoint.part === "leftElbow");
      const rightElbow = pose.keypoints.find((keypoint) => keypoint.part === "rightElbow");
      const leftWrist = pose.keypoints.find((keypoint) => keypoint.part === "leftWrist");
      const rightWrist = pose.keypoints.find((keypoint) => keypoint.part === "rightWrist");

      const leftKneeAngle = calculateAngle(leftHip.position, leftKnee.position, leftAnkle.position);
      const leftElbowAngle = calculateAngle(leftShoulder.position, leftElbow.position, leftWrist.position);
      const rightElbowAngle = calculateAngle(rightShoulder.position, rightElbow.position, rightWrist.position);

      setKneeAngles((prevAngles) => [...prevAngles, leftKneeAngle]);

      const midHipPosition =
      {
        x: (leftHip.position.x + rightHip.position.x) / 2,
        y: (leftHip.position.y + rightHip.position.y) / 2,
      };

      if (frame_id === 3) {
        startCountdown();
        // setGoMesage("Now You Can Start")
        initial_dist = euclideanDist(leftKnee.position, rightKnee.position)
        initial_gap = euclideanDist(leftHip.position, leftKnee.position)
        // console.log('initial distance', initial_dist);
      }

      if (frame_id === 24) {
        setGoMessage("")
      }

      // distance in cm
      // Define your conversion factor (pixels per centimeter) based on your setup
      const pixelsPerCentimeter = 10; // Adjust this based on your setup
      const distanceInCentimeters = calculateDistanceInCentimeters(leftKnee, leftAnkle, pixelsPerCentimeter);
      // console.log( Math.round(distanceInCentimeters));
      setDistance(Math.round(distanceInCentimeters));

      if (frame_id >= 10) {
        // Using the score, we make sure to detect only when keypoints are visible
        if (leftHip.score > 0.50 && leftKnee.score > 0.50 && leftAnkle.score > 0.0 && leftShoulder.score > 0.0 && leftElbow.score > 0.0 && leftWrist.score > 0.0 && rightHip.score > 0.0 && rightKnee.score > 0.50 && rightAnkle.score > 0.0 && rightShoulder.score > 0.0 && rightElbow.score > 0.0 && rightWrist.score > 0.0) {
          detectSquat(leftHip.position, leftKnee.position, initial_gap)
          distanceKnees(leftKnee.position, rightKnee.position, initial_dist)
          checkElbowAngle(leftElbowAngle, rightElbowAngle)
          // console.log("hi")
        }
      }

      const keypointsToStore = pose.keypoints.map((keypoint) => (
        {
          part: keypoint.part,
          position: { x: keypoint.position.x.toFixed(2), y: keypoint.position.y.toFixed(2) },
        }));

      setKeypointsData((prevData) => [...prevData, keypointsToStore]);

      drawCanvas(pose, video, videoWidth, videoHeight, canvasRef);
      // tf.disposeVariables();
    }
  };

  function euclideanDist(point1, point2) {
    return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
  }

  const calculateAngle = (pointA, pointB, pointC) => {
    // between three points (pointA, pointB, pointC).
    const radians = Math.atan2(pointC.y - pointB.y, pointC.x - pointB.x) - Math.atan2(pointA.y - pointB.y, pointA.x - pointB.x);
    // Convert radians to degrees.
    const degrees = (radians * 180) / Math.PI;
    return degrees;
  };

  function distanceKnees(leftKnee, rightKnee, initialDist, threshold = 10) {
    const dist = euclideanDist(leftKnee, rightKnee);
    // console.log(leftKnee, rightKnee)
    // console.log('distance', dist);
    if (dist < (initialDist - threshold)) {
      // console.log('Knee distance reduced');
      // setKneeBend('Knee distance reduced');
    }
    else {
      // setKneeBend("")
    }
  }

  function calculateDistanceInCentimeters(keypoint1, keypoint2, pixelsPerCentimeter) {
    const deltaX = keypoint1.x - keypoint2.x;
    const deltaY = keypoint1.y - keypoint2.y;
    const pixelDistance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
    const centimeterDistance = pixelDistance / pixelsPerCentimeter;
    return centimeterDistance;
  }

  let flag1 = 0;

  const detectSquat = (leftHip, leftKnee, initial_gap) => {
    // console.log(rightHip.y, rightKnee.y, rightHip.x, rightKnee.x, rightHip.score, rightKnee.score)
    // console.log("hi")
    // console.log(leftHip.y)

    if (leftHip.y > leftKnee.y) {
      // console.log("hip below knee")
      setFlag("hip below knee")
      flag1 = 1;
      // console.log("flag is", flag1)
      // console.log("befor", leftHip.y, leftKnee.y)
    }
    else if (((Math.abs(euclideanDist(leftHip, leftKnee) - initial_gap)) < 10) && flag1 === 1) {
      // console.log("hip above knee")
      // setFlag("hip above knee")
      setCount((prevCount) => prevCount + 1);
      flag1 = 0;
      console.log("count is", count)
      // console.log("after", leftHip.y, leftKnee.y)
    }
    else {
      setFlag("")
    }

    // if (flag === 1){
    // console.log("squat detected" + rightHip.y)
    // }
  }

  const checkElbowAngle = (leftElbowAngle, rightElbowAngle) => {
    // Defining threshold angle for a correct elbow position
    const correctElbowAngleThreshold = 180;

    // Checking if both left and right elbow angles are within the correct range
    if ((correctElbowAngleThreshold - 10) <= leftElbowAngle &&
      (correctElbowAngleThreshold - 10) <= rightElbowAngle <= (correctElbowAngleThreshold + 10)) {
      // Both elbows are in a correct position
      // console.log(leftElbowAngle);
      setElbowBend("Elbow Bend Detected");
    }
    else {
      // At least one elbow is not in the correct position
      // console.log('Elbow bend detected');
      setElbowBend("");
    }
  };

  const drawCanvas = (pose, video, videoWidth, videoHeight, canvas) => {
    // console.log("drawCanvas")
    const ctx = canvas.current.getContext("2d");
    canvas.current.width = videoWidth;
    canvas.current.height = videoHeight;

    const keypointsToDraw = pose["keypoints"].filter((keypoint) => {
      return keypoint.part === "leftHip" || keypoint.part === "leftKnee" || keypoint.part === "leftAnkle" || keypoint.part === "leftShoulder" || keypoint.part === "rightHip" || keypoint.part === "rightKnee" || keypoint.part === "rightShoulder" || keypoint.part === "rightAnkle";
    });

    // console.log(keypointsToDraw)
    drawKeypoints(keypointsToDraw, 0.6, ctx);
    // drawSkeleton(pose["keypoints"], 0.7, ctx);
    const leftHip = keypointsToDraw.find((keypoint) => keypoint.part === "leftHip");
    const leftKnee = keypointsToDraw.find((keypoint) => keypoint.part === "leftKnee");
    const leftAnkle = keypointsToDraw.find((keypoint) => keypoint.part === "leftAnkle");
    const leftShoulder = keypointsToDraw.find((keypoint) => keypoint.part === "leftShoulder");

    const rightHip = keypointsToDraw.find((keypoint) => keypoint.part === "rightHip");
    const rightKnee = keypointsToDraw.find((keypoint) => keypoint.part === "rightKnee");
    const rightAnkle = keypointsToDraw.find((keypoint) => keypoint.part === "rightAnkle");
    const rightShoulder = keypointsToDraw.find((keypoint) => keypoint.part === "rightShoulder");

    ctx.font = "14px Arial";

    if (leftHip && leftKnee && leftShoulder && leftAnkle) {
      // Print left keypoints y-coordinates on the top left corner
      const text = `Left Y Coordinates:`;
      const textWidth = ctx.measureText(text).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the left keypoints
      ctx.fillRect(10, 20, textWidth + 10, 20); // Highlight rectangle for left keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(text, 15, 35); // Print the text
      const text_shoulder = `Shoulder: ${leftShoulder.position.y.toFixed(2)}`;
      const textWidthShoulder = ctx.measureText(text_shoulder).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the left keypoints
      ctx.fillRect(10, 40, textWidthShoulder + 10, 20); // Highlight rectangle for left keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(text_shoulder, 15, 55); // Print the text
      const text_hip = `Hip: ${leftHip.position.y.toFixed(2)}`;
      const textWidthHip = ctx.measureText(text_hip).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the left keypoints
      ctx.fillRect(10, 60, textWidthHip + 10, 20); // Highlight rectangle for left keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(text_hip, 15, 75); // Print the text
      const text_knee = `Knee: ${leftKnee.position.y.toFixed(2)}`;
      const textWidthKnee = ctx.measureText(text_knee).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the left keypoints
      ctx.fillRect(10, 80, textWidthKnee + 10, 20); // Highlight rectangle for left keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(text_knee, 15, 95); // Print the text
      const text_ankle = `Ankle: ${leftAnkle.position.y.toFixed(2)}`;
      const textWidthAnkle = ctx.measureText(text_ankle).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the left keypoints
      ctx.fillRect(10, 100, textWidthAnkle + 10, 20); // Highlight rectangle for left keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(text_ankle, 15, 115); // Print the text
    }

    if (rightHip && rightKnee && rightShoulder && rightAnkle) {
      // Print right keypoints y-coordinates on the top right corner
      const text = `Right Y Coordinates:`;
      const textWidth = ctx.measureText(text).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the right keypoints
      ctx.fillRect(videoWidth - textWidth - 10, 20, textWidth + 10, 20); // Highlight rectangle for right keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(text, videoWidth - textWidth - 5, 35); // Print the text
      const text_shoulder = `Shoulder: ${rightShoulder.position.y.toFixed(2)}`;
      const textWidthShoulder = ctx.measureText(text_shoulder).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the left keypoints
      ctx.fillRect(videoWidth - textWidthShoulder - 10, 40, textWidthShoulder + 10, 20); // Highlight rectangle for left keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(text_shoulder, videoWidth - textWidthShoulder - 5, 55); // Print the text
      const text_hip = `Hip: ${rightHip.position.y.toFixed(2)}`;
      const textWidthHip = ctx.measureText(text_hip).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the left keypoints
      ctx.fillRect(videoWidth - textWidthHip - 10, 60, textWidthHip + 10, 20); // Highlight rectangle for left keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(text_hip, videoWidth - textWidthHip - 5, 75); // Print the text
      const text_knee = `Knee: ${rightKnee.position.y.toFixed(2)}`;
      const textWidthKnee = ctx.measureText(text_knee).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the left keypoints
      ctx.fillRect(videoWidth - textWidthKnee - 10, 80, textWidthKnee + 10, 20); // Highlight rectangle for left keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(text_knee, videoWidth - textWidthKnee - 5, 95); // Print the text
      const text_ankle = `Ankle: ${rightAnkle.position.y.toFixed(2)}`;
      const textWidthAnkle = ctx.measureText(text_ankle).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the left keypoints
      ctx.fillRect(videoWidth - textWidthAnkle - 10, 100, textWidthAnkle + 10, 20); // Highlight rectangle for left keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(text_ankle, videoWidth - textWidthAnkle - 5, 115); // Print the text
    }

    if (leftHip && rightHip && leftKnee && rightKnee && leftShoulder && rightShoulder) {
      // Print the difference between left and right hip Y-coordinates on the bottom left corner
      const textShoulder = `Shoulder Tilt: ${(leftShoulder.position.y - rightShoulder.position.y).toFixed(2)}`;
      const textWidthShoulder = ctx.measureText(textShoulder).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the left keypoints
      ctx.fillRect(10, videoHeight - 120, textWidthShoulder + 10, 20); // Highlight rectangle for left keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(textShoulder, 15, videoHeight - 105); // Print the text
      const textHip = `Hip Tilt: ${(leftHip.position.y - rightHip.position.y).toFixed(2)}`;
      const textWidthHip = ctx.measureText(textHip).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the left keypoints
      ctx.fillRect(10, videoHeight - 100, textWidthHip + 10, 20); // Highlight rectangle for left keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(textHip, 15, videoHeight - 85); // Print the text
      const textKnee = `Knee Tilt: ${(leftKnee.position.y - rightKnee.position.y).toFixed(2)}`;
      const textWidthKnee = ctx.measureText(textKnee).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the left keypoints
      ctx.fillRect(10, videoHeight - 80, textWidthKnee + 10, 20); // Highlight rectangle for left keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(textKnee, 15, videoHeight - 65); // Print the text
      const textKneeX = `Knee V: ${(leftKnee.position.x - rightKnee.position.x).toFixed(2)}`;
      const textWidthKneeX = ctx.measureText(textKneeX).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the right keypoints
      ctx.fillRect(10, videoHeight - 60, textWidthKneeX + 10, 20); // Highlight rectangle for right keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(textKneeX, 15, videoHeight - 45); // Print the text
      const textDepth = `Depth: ${((leftHip.position.y - leftKnee.position.y) + (rightHip.position.y - rightKnee.position.y)).toFixed(2)}`;
      const textWidthDepth = ctx.measureText(textDepth).width;
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Set semi-transparent yellow color for the right keypoints
      ctx.fillRect(10, videoHeight - 40, textWidthDepth + 10, 20); // Highlight rectangle for right keypoints
      ctx.fillStyle = "white"; // Set color for the text
      ctx.fillText(textDepth, 15, videoHeight - 25); // Print the text
    }

    const minScoreThreshold = 0.7;

    if (leftHip && leftHip.score >= minScoreThreshold && leftKnee && leftKnee.score >= minScoreThreshold) {
      ctx.beginPath();
      ctx.moveTo(leftHip.position.x, leftHip.position.y);
      ctx.lineTo(leftKnee.position.x, leftKnee.position.y);
      ctx.lineTo(leftAnkle.position.x, leftAnkle.position.y);
      ctx.closePath();
      // ctx.strokeStyle = "red"; // Set the line color
      // ctx.stroke();
    }

    if (leftHip && leftKnee && leftAnkle) {

      // Calculate the vectors
      const vectorHipToKnee =
      {
        x: leftKnee.position.x - leftHip.position.x,
        y: leftKnee.position.y - leftHip.position.y,
      };

      const vectorKneeToAnkle =
      {
        x: leftAnkle.position.x - leftKnee.position.x,
        y: leftAnkle.position.y - leftKnee.position.y,
      };

      // Calculate the angle between the vectors
      const angleInRadians = Math.atan2(vectorKneeToAnkle.y, vectorKneeToAnkle.x) - Math.atan2(vectorHipToKnee.y, vectorHipToKnee.x);
      let angleInDegrees = (angleInRadians * 180) / Math.PI;
      // Ensure the angle is positive and within the range [0, 360]

      if (angleInDegrees < 0) {
        angleInDegrees += 360;
      }

      // Print the angle
      // setDegree( Math.round(angleInDegrees));
    };

    if (playing) {
      requestAnimationFrame(() => drawCanvas(pose, videoWidth, videoHeight));
    }
  };

  // const saveDataToFile = () => 
  // {
  //   const jsonData = JSON.stringify(keypointsData[24], null, 2);
  //   // const blob = new Blob([jsonData], { type: "application/json" });
  //   // const url = URL.createObjectURL(blob);
  //   // const a = document.createElement("a");
  //   // a.href = url;
  //   // a.download = "keypoints_data.json";
  //   // document.body.appendChild(a);
  //   // a.click();
  //   // document.body.removeChild(a);
  //   // generatePDF(jsonData);
  //   const doc = new jsPDF();
  //   const data = JSON.parse(jsonData);

  //   const clientName = 'John Doe';
  //   const phoneNumber = '123-456-7890';
  //   const age = '44'
  //   const mail = 'john.doe@gmail.com'
  //   const documentTitle = 'Key Points Data';

  //   // Add title// Add title
  //   doc.setFontSize(18);
  //   doc.text(documentTitle, 14, 15);

  //   // Add client information
  //   doc.setFontSize(12);
  //   doc.text(`Client: ${clientName}`, 14, 25);
  //   doc.text(`Phone Number: ${phoneNumber}`, 14, 35);
  //   doc.text(`Age: ${age}`, 14, 45);
  //   doc.text(`Email: ${mail}`, 14, 55);

  //   doc.autoTable({
  //     head: [['Part', 'X-Position', 'Y-Position']],
  //     body: data.map(item => [item.part, item.position.x, item.position.y]),
  //     startY: 60 // Adjust the startY value based on your requirements
  //   });

  //   // Save the PDF
  //   doc.save('keypoints_data.pdf');
  // };
  const saveDataToFile = async () => {
    const jsonData = JSON.stringify(keypointsData[24], null, 2);
    // // const blob = new Blob([jsonData], { type: "application/json" });
    // // const url = URL.createObjectURL(blob);
    // // const a = document.createElement("a");
    // // a.href = url;
    // // a.download = "keypoints_data.json";
    // // document.body.appendChild(a);
    // // a.click();
    // // document.body.removeChild(a);
    // // generatePDF(jsonData);


    // const doc = new jsPDF();
    // const data = JSON.parse(jsonData);

    // const clientName = 'John Doe';
    // const phoneNumber = '123-456-7890';
    // const age = '44'
    // const mail = 'john.doe@gmail.com'
    // const documentTitle = 'Key Points Data';

    // // Add title// Add title
    // doc.setFontSize(18);
    // doc.text(documentTitle, 14, 15);

    // // Add client information
    // doc.setFontSize(12);
    // doc.text(Client: ${clientName}, 14, 25);
    // doc.text(Phone Number: ${phoneNumber}, 14, 35);
    // doc.text(Age: ${age}, 14, 45);
    // doc.text(Email: ${mail}, 14, 55);

    // doc.autoTable({
    //   head: [['Part', 'X-Position', 'Y-Position']],
    //   body: data.map(item => [item.part, item.position.x, item.position.y]),
    //   startY: 60 // Adjust the startY value based on your requirements
    // });

    // // Save the PDF
    // const pdfContent = doc.output('blob'); // Get the blob content
    // console.log("pdf", pdfContent)

    try {
      console.log("hellooo")
      const response = await fetch('http://localhost:5000/save-pdf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ jsonData }),
      })
        .then(response => response.json())
        .then(data => {
          console.log('PDF saved successfully:', data.filePath);
        })
        .catch(error => {
          console.error('Error saving PDF:', error);
        });
    } catch (error) {
      console.error('An error occurred:', error);
    }

  };
  const handleOverlayClick = () => {
    const video = videoRef.current;

    if (!playing) {
      video.play();
    } else {
      video.pause();
    }

    setPlaying(!playing);
  };

  const startCam = () => {
    setIsShowVideo(true);
    setWebcamPlaying(true);
  }

  const stopCam = () => {
    setIsShowVideo(false);
    setWebcamPlaying(false);
  }

  const sendReport = () => {
    // Handle the logic to send the report
    saveDataToFile();
    navigate('/report');
  };

  return (
    <>
      <div className="top-content">
        <h1>Movement Assessment</h1>
        <h2>Elevate your well-being through the beauty of movement</h2>
        <p>Movement assessment is a process of evaluating an individuals physical capabilities, mobility, stability, and overall movement patterns. It involves observing and analyzing how a person performs various exercises and functional movements to identify any limitations, asymmetries, or dysfunctions in their body mechanics. This assessment is commonly used in fitness, physical therapy, and sports performance settings to create personalized exercise programs, prevent injuries, and optimize movement efficiency.</p>
      </div>
      <div className="split-screen-container">
        <div className="demo-video">
          <div className="border-wrapper">
            <div className="video-wrapper" onClick={handleOverlayClick}>
              <video ref={videoRef} width="auto" height="535">
                <source src={squatFrontView} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {!playing && (
                <img
                  src={playButtonImage}
                  alt="Play Button"
                  className="play-button-image"
                  onClick={handleOverlayClick}
                />
              )}
            </div>
          </div>
        </div>
        <div className="our-video">
          <header className="App-header">
            {isShowVideo && !assessmentCompleted && (
              <>
                {timer > 0 && (
                  <div className="countdown-timer">
                    <p>{timer}</p>
                  </div>
                )}
                {timer === 0 && (
                  <div className="countdown-timer">
                    <p1>{goMessage}</p1>
                  </div>
                )}
                <Webcam
                  ref={webcamRef}
                  audio={false}
                  mirrored={true}
                  style={{
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "340px",
                    // left: 0,
                    // right: 0,
                    // textAlign: "center",
                    zIndex: 9,
                    width: 765,
                    height: 575,
                    border: "1px solid #ED5009",
                    borderRadius: "24px",
                    backgroundColor: "#D9D9D9",
                  }}
                />
                <canvas
                  ref={canvasRef}
                  style={{
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "340px",
                    // left: 0,
                    // right: 0,
                    // textAlign: "center",
                    zIndex: 9,
                    width: 765,
                    height: 575,
                    border: "1px solid #ED5009",
                    borderRadius: "24px",
                  }}
                />
              </>
            )}
            {!isShowVideo && (
              <canvas
                ref={canvasRef}
                style={{
                  position: "absolute",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "340px",
                  // left: 0,
                  // right: 0,
                  // textAlign: "center",
                  zIndex: 9,
                  width: 765,
                  height: 575,
                  border: "1px solid #ED5009",
                  borderRadius: "24px",
                  backgroundColor: "#D9D9D9",
                }}
              />
            )}
            {!isShowVideo && !assessmentCompleted && (
              <button
                onClick={startCam}
                style={{
                  position: "absolute",
                  zIndex: 11,
                  marginBottom: "375px",
                  // marginRight:"25px",
                  width: "284px",
                  height: "68px",
                  boxShadow: "none",
                  borderRadius: "18px",
                  font: "Urbanist",
                  fontSize: "18px",
                  fontWeight: 600,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                Start <img src={arrow} width={24} height={24} />
              </button>
            )}
            {assessmentCompleted && !isShowVideo && (
              <button
                onClick={sendReport}
                style={{
                  position: "absolute",
                  zIndex: 12,
                  marginBottom: "375px",
                  // marginRight: "325px",
                  width: "284px",
                  height: "68px",
                  boxShadow: "none",
                  borderRadius: "18px",
                  font: "Urbanist",
                  fontSize: "18px",
                  fontWeight: 600,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Send Report <img src={arrow} width={24} height={24} />
              </button>
            )}
          </header>
          {/* <button onClick={saveDataToFile}>Save Keypoints Data</button> */}
        </div>
      </div>
    </>
  );
}

export default Camera;