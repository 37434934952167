import React from 'react';
import './Thankyou.css';
import arrow from './chevron-right.png'

const Thankyou = () => {
    return (
        <div className='thank-you'>
            <h1>Thank You</h1>
            <p>The report of the exercise will be sent through Email  or WhatsApp.</p>
            <button>GO BACK <img src = { arrow } /></button>
        </div>
    );
};

export default Thankyou;
